.rates-sticky-block {
	display: flex;
	flex-direction: row;
	height: 100%;
	align-items: center;
	position: sticky;
	left: 0;
	background-color: #fff;
	z-index: 100;
}
.rates-result-box {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e1e1e1;
	height: 80px;
	border-left: 1px solid #e1e1e1;
	border-right: 1px solid #e1e1e1;
	box-sizing: border-box;
}
.rates-result-box div {
	border-top: none;
}
.rates-title-border {
	border-bottom: 1px solid #bfbfbf;
	margin: 0 auto 20px;
	width: 96%;
}
.rates-body {
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	height: 80px;
}
.rates-icon {
	padding-left: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-width: 50px;
}
.rates-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 0 15%;
	width: 100px;
	height: 100%;
	padding-right: 40px;
}
.rates-platform {
	padding: 0 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-width: 280px;
}
.rates-category {
	padding: 0 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-width: 150px;
}
.rates-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #5a5a5a;
}
.rates-img-border {
	border: 0.5px solid #bfbfbf;
}
.rates-modal-input {
	width: 50px;
	height: 36px;
	margin-bottom: 0px;
	text-align: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 40px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.rates-modal-input-value {
	background: #f8f8f8;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 6px;
}
.rates-sticky-block {
	display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 100;
	min-width: 480px !important;
}
