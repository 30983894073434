.uploading-header {
    background: linear-gradient(90deg, #E51B15 0.33%, #8B0A0A 100%), linear-gradient(0deg, #E51B15, #E51B15), linear-gradient(90deg, #C42114 0.33%, #8B0A0A 100%), linear-gradient(270deg, #575F64 0%, #293034 100%), #314455;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.uploading-header-cont {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.upload-cont {
    margin-top: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    padding: 32px 27px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px;
}
.upload-cont-main {
    margin-top: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    padding: 32px 27px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px;
    max-width: 1000px;
}
.upload-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #5A5A5A;    
}
.upload-completed {
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px;
    flex-direction: row;
}
.successfull-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #299C00;
    margin-left: 20px;
    max-width: 500px;
}
.choose-platforms-cont {
    max-width: 1053px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.upload-platform-box {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.upload-platform-countries {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.upload-platform-country {
    background: #F2F2F2;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #5A5A5A;
    padding: 6px 12px;
    margin: 5px;
}
@media screen and (max-width: 991px) {
    .upload-completed {
        margin: 30px 20px 0;
        padding: 17px 0;
    }
    .choose-platforms-cont {
        flex-direction: column;
        align-items: center;
    }
    .upload-cont, .upload-cont-main {
       max-width: 480px;
       margin-top: 40px;
    }
}
@media screen and (max-width: 496px) {
    .upload-cont,.upload-cont-main {
        max-width: 280px;
        padding: 20px 15px;
     }
     .upload-platform-countries {
        padding-left: 0px;
     }
     .upload-platform-box {
        flex-direction: column;
     }
     .upload-platform-country {
        margin: 5px 0;
     }
     .successfull-text {
        max-width: 200px;
     }
}
