.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  z-index: 1200;
}
.modal__body {
  width: auto;
  height: auto;
  margin: 0 10px;
  background: #ffffff;
  padding: 10px;
  /* border-radius: 10px; */
  text-align: center;
  position: relative;
  overflow: auto;
  max-height: 100%;
}
.modal__body_edit {
  width: initial;
  min-width: 400px;
}
.modal__body_schedule {
  width: initial;
  min-width: 400px;
  height: 350px;
}
.modal__body_form {
  width: 500px;
  height: 500px;
  margin: auto;
  background: #ffffff;
  margin-top: auto;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.modal__close-button {
  position: absolute;
  top: -7px;
  right: 7px;
  color: rgba(57, 57, 57, 0.66);
  cursor: pointer;
  font-size: 35px;
}
.modal__close-button:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 769px) {
  .modal__body {
    margin: 0 10px !important;
  }
}
.MuiPopover-root {
  z-index: 12000 !important;
}