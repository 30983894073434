.contrato-bg {
    z-index: 1100;
    width: 100%;
    height: 100%;
    background: #EDEBE4 !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
}

.contrato-cont {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 800px;
}
.contrato-box {
    background: #FFFFFF;
    border-radius: 10px;
    max-height: 600px;
    width: 100%;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
    padding-bottom: 40px;
}
.policy-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: #5A5A5A;    
}
.logout-btn-onboarding {
    border-radius: 6px;
    border: 1px solid #ACACAC;
    background: #EDEBE4;
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    padding: 10px 20px;
}
@media screen and (max-width: 991px) {
    .contrato-box {
        margin: 10px;
        max-width: 600px;
    }
}
@media screen and (max-width: 769px) {
    .contrato-box {
        max-width: 450px;
    }
}
@media screen and (max-width: 496px) {
    .contrato-box {
        max-width: 280px;
    }
}
