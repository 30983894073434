.active-margin-vendor {
  margin-left: -40px;
}
.vendors-title-image {
  min-width: 80px !important;
  width: 80px !important;
  height: 80px !important;
  margin: 0 10px;
  /* background: #5a5a5a; */
}
.vendors-title,
.vendors-country,
.vendors-products {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
}
.vendors-accordion,
.vendors-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vendors-accordion {
  min-width: 80px;
  flex: 0 1 5%;
}
.vendors-title {
  min-width: 450px;
  flex: 0 1 33%;
}
.vendors-country,
.vendors-contact {
  min-width: 120px;
  flex: 0 1 16%;
}
.vendors-products {
  min-width: 100px;
  flex: 0 1 10%;
}
.seller-title-cat {
  flex: 0 1 52.7%;
  width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5a5a5a;
}
.seller-cat-country {
  width: 900px;
  flex: 0 1 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 88px;
}
.seller-cat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200;
  flex: 0 1 20%;
}
.sellers-cat-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 320px;
  flex: 0 0 80%;
}
.seller-cat,
.sellers-cat-type .mapping-modal-text {
  text-transform: capitalize;
}
.sellers-cat-rate {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100px;
  flex: 1 1 20%;
}
.sellers-cat-type-add {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #e1e1e1;
  padding: 15px 0;
}
.sellers-cat-type-add-text,
.sellers-cat-type-add-text-sel {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #e51b15;
  text-transform: capitalize;
}
.sellers-cat-type-add-text-sel {
  color: #5a5a5a;
}
.seller-product-text {
  font-size: 18px;
  line-height: 21px;
  color: #5a5a5a;
  font-weight: 400;
  margin-bottom: 10px;
}
.seller-product-tab {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #e51b15 !important;
  text-transform: initial !important;
  border-bottom: 1px solid transparent !important;
  margin: 10px !important;
  box-shadow: none !important;
}
.active-tab-default,
.seller-product-tab:active,
.seller-product-tab:hover,
.seller-product-tab:focus {
  color: #5a5a5a !important;
  border-bottom: 1px solid #5a5a5a !important;
  font-weight: 700 !important;
}
.seller-product-filter-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
}
.seller-product-subtext {
  font-size: 13px;
  line-height: 20px;
  color: #5a5a5a;
}
.seller-product-subtext-blue {
  color: #e51b15;
}
.sellers-product-sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #acacac;
}
.vendors-product-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
}
.vendors-modal-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: start;
  padding: 20px;
  margin-bottom: 20px;
  background: #f2f2f2;
}
.table-vendormm-title-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}
.table-vendormm-title-image {
  min-width: 210px;
  width: 210px;
  min-height: 160px;
  height: 160px;
  margin-right: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.table-vendormm-title-imagebg {
  width: 100%;
  height: 100%;
  background: #d8ceb9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-vendormm-title-image-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.table-vendormm-title-image-text {
  font-size: 14px;
  line-height: 16px;
  color: #acacac;
}
.table-vendormm-title-box {
  width: 100%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.table-vendormm-bold-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
}
.vendormm-add-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #acacac;
  cursor: pointer;
  width: 100%;
  text-align: start;
  margin: 20px 0;
}
.vendormm-remove-button {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #acacac;
  cursor: pointer;
}
.table-vendormm-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-main-cont .table-main-cont {
  padding: 0;
}
@media screen and (max-width: 769px) {
  .table-vendormm-title-cont {
    flex-direction: column;
    align-items: center;
  }
}
.int-tab-sync {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #e51b15;
  border-bottom: 2px solid transparent;
  padding: 0 10px 10px;
}
.int-tab-sync:active,
.int-tab-sync:focus,
.int-tab-sync:hover {
  color: #5a5a5a;
  border-bottom: 2px solid #5a5a5a;
}
.int-tab-sync-active {
  font-weight: bold;
  color: #5a5a5a;
  border-bottom: 2px solid #5a5a5a;
}
.onborading-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  min-width: 350px;
  flex: 0 1 32%;
}
.onborading-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  width: 120px;
  flex: 0 1 13%;
  text-align: center;
}
.sel-set-actions-modal {
  background: #ffffff;
  border: 1px solid #e51b15;
  border-radius: 5px;
  padding: 8px 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e51b15;
  box-shadow: none;
}
.sel-set-actions-modal-check {
  background: #ffffff;
  border: 1px solid #299c00;
  border-radius: 5px;
  padding: 5px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #299c00;
  box-shadow: none;
  flex-direction: row;
  margin-top: 5px;
}
/* .sel-set-actions-modal-check:hover {
	color: #FFFFFF;
	background: #299C00;
  } */
.sel-red-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e80000;
}
.sel-orange-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #f1692e;
}
.sel-set-list-active-prod-modal-image {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 1 80px;
  text-align: start;
}
.sel-set-list-active-prod-modal-sku {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  flex: 0 1 200px;
  text-align: start;
}
.sel-set-list-active-prod-modal-title {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  flex: 0 1 60%;
  text-align: start;
}
.sel-plan-modal-greenbg {
  background: #dff1d9;
  padding: 10px 20px;
}
.sel-modal-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
}
.sel-modal-form-button-cancel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #e51b15;
  background-color: #fff;
  border: none;
  box-shadow: none;
}
.sel-pla-modal-green {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #299c00;
}
.sel-pla-modal-grey {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #acacac;
}
.sel-plan-modal-greybg {
  background: #f8f8f8;
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
.sel-pricing-modal-area {
  background: #f8f8f8;
  border: 1px solid #acacac;
  border-radius: 6px;
  width: 100%;
  min-height: 80px;
  padding: 5px;
  resize: none;
}
.sel-newon-cont {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  margin: 30px 0;
  max-width: 740px;
  padding: 25px 30px;
  width: 100%;
}
.sel-newon-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #5a5a5a;
}
.sel-newon-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
}
.sel-newon-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 0 1 40%;
  margin: 20px 0;
}
.sel-newon-input {
  width: 100%;
  background: #f2f2f2;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 40px;
  margin-top: 8px;
  padding-left: 10px;
}
