.tax-head {
  background: #f2f2f2;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a !important;
  height: 80px;
}
.tax-title-button {
  background: #E51B15;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tax-body {
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  height: 80px;
}
.tax-border {
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 10px;
}
.tax-cat {
  display: flex;
  align-items: center;
  flex: 0 20%;
  padding: 0 10px;
  width: 450px;
  height: 100%;
}
.mapping-title-border {
  border-bottom: 1px solid #bfbfbf;
  margin: 0 auto 20px;
  width: 96%;
}
.tax-imp {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  flex: 0 10%;
  width: 200px;
  height: 100%;
}
.tax-lg {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 0 10%;
  width: 160px;
  height: 100%;
}
.tax-border-bottom {
  border: 1px solid #e1e1e1;
  width: 100%;
}
.mapping-modal-cont {
  max-height: 700px;
  width: 520px;
  overflow-y: auto;
  padding: 10px;
}
.mapping-modal-title-text {
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #000000;
}
.tax-modal-adicionar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  text-transform: initial;
  color: #5a5a5a;
  padding: 10px 0;
}
.tax-modal-cat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.tax-modal-cat-zeoos-client {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.zeoos-client-label {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  /* padding-bottom: 5px; */
  text-transform: initial;
  margin-right: 10px;
}
.mapping-modal-text {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  padding-bottom: 5px;
  text-transform: initial;
}
.tax-modal-input {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  padding-left: 5px;
  resize: none;
}
.tax-modal-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.mapping-modal-table {
  width: 100%;
}
.mapping-modal-results-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  border-bottom: 2px solid #e1e1e1;
}
.mapping-modal-result-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  border-bottom: 2px solid #e1e1e1;
}
.mapping-modal-result-box:last-child {
  border-bottom: 2px solid transparent;
}
.tax-modal-pais {
  display: flex;
  align-items: center;
  flex: 0 45%;
  margin-right: 20px;
  max-width: 200px;
  height: 100%;
}
.tax-modal-iva {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  flex: 0 15%;
  max-width: 70px;
  height: 100%;
}
.tax-modal-iec {
  display: flex;
  margin-right: 20px;
  flex-direction: row;
  align-items: center;
  flex: 0 25%;
  max-width: 150px;
  height: 100%;
}
.tax-modal-del {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 15%;
  max-width: 100px;
  height: 100%;
}
.tax-modal-select {
  max-width: 200px;
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
}
.mapping-modal-table-input {
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  max-width: 40px;
  padding-left: 4px;
}
.mapping-modal-widthx2 {
  max-width: 80px;
}
.mapping-modal-subtext {
  font-size: 16px;
  line-height: 30px;
  color: #acacac;
  padding-left: 2px;
  text-transform: initial;
}
.tax-modal-table-trash {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 6px;
}
@media screen and (max-width: 767px) {
  .tax-title-button {
    margin-right: 0;
  }
  .mapping-modal-cont {
    width: 100%;
  }
  .tax-modal-iva,
  .tax-modal-pais,
  .tax-modal-iec {
    margin-right: 5px;
  }
  .tax-title-button {
    margin-top: 20px !important;
  }
}
