.gestao-select-cont {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.gestao-select {
	border-radius: 5px;
	padding: 10px;
	margin: 5px 20px;
}

.gestao-select-text {
	text-transform: uppercase;
	font-weight: 500;
}

.gestao-select-button {
	padding: 10px 5px;
	margin: 0 10px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #006dd2;
	box-shadow: none;
	border-radius: 0;
	border: none;
	background: transparent;
	border-bottom: 1px solid transparent;
}

.gestao-select-button:focus,
.gestao-select-button:active,
.gestao-select-button:checked {
	border-bottom: 1px solid #5a5a5a;
	color: #ffffff;
	font-weight: bold;
}

.gestao-image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 70px;
	text-align: center;
}

.gestao-table-image {
	width: 60px !important;
	max-height: 60px !important;
	background: #e2e2e2;
	border-radius: 3px;
}

.gestao-sku {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60px;
	text-align: center;
}

.gestao-ean {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	text-align: center;
	width: 120px;
	padding-left: 10px;
}

.gestao-image-link {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-width: 30px;
}

.gestao-title {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 280px;
	padding-left: 10px;
}

.gestao-total {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 120px;
	flex: 0 8%;
}

.gestao-uni {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	min-width: 280px;
	flex: 0 30%;
	padding-left: 20px;
}

.gestao-sticky-block {
	display: flex;
	flex-direction: row;
	height: 100%;
	align-items: center;
	position: sticky;
	left: 0;
	background-color: #fff;
	z-index: 100;
}

.g-delete-icon {
	opacity: 0.5;
}
.g-delete-icon:hover {
	opacity: 1;
}

.gestao-sticky-block:hover {
	background-color: #f2f2f2;
}

.title-border {
	border-radius: 10px 0 0 0;
}

.gestao-vinuus {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	/* flex: 0 1 12%; */
	position: relative;
	width: 150px;
	border: 1px solid transparent;
	height: 99%;
}

/* .gestao-vinuus:hover {
	border: 1px solid #006dd2;
} */

.gestao-cost,
.gestao-stock {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding-left: 5px;
	font-weight: normal !important;
}

.gestao-cost {
	width: 65%;
	justify-content: flex-end;
	padding-right: 5px;
}

.gestao-stock {
	width: 35%;
	justify-content: center;
}
.inv-title-modal1-cont {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background: #f2f2f2;
	padding: 14px;
	margin: 10px 0;
}
.inv-title-modal1-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	text-align: left;
}
.inv-modal1-tab {
	background: #5a5a5a !important;
	border: 1px solid #5a5a5a !important;
	border-radius: 5px 5px 0px 0px !important;
	padding: 8px 22px !important;
	font-family: "Roboto" !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 16px !important;
	display: flex !important;
	align-items: center !important;
	text-align: center !important;
	text-transform: uppercase !important;
	color: #ffffff !important;
}
.ges-tab-sync {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #006dd2 !important;
	border-bottom: 2px solid transparent;
	padding: 0 10px 10px;
	border: none;
	box-shadow: none;
	text-transform: initial !important;
}
.ges-tab-sync:active,
.ges-tab-sync:focus,
.ges-tab-sync:hover {
	color: #5a5a5a !important;
	border-bottom: 2px solid #5a5a5a;
}
.ges-tab-sync-active {
	font-weight: bold;
	color: #006dd2 !important;
	border-bottom: 2px solid #5a5a5a;
}
.inv-mod-tabs {
	border-radius: 5px;
	border: 1px solid #e1e1e1;
	background: #fff;
}
.gestao-sku-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #5a5a5a;
}

.gestao-ean-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #5a5a5a;
}

.gestao-title-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.gestao-total-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
}

.gestao-cost-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.gestao-stock-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.gestao-cost-update {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #b4b4b4;
	border-bottom: 1px solid #b4b4b4;
	cursor: pointer;
}

.gestao-cost-update:hover {
	color: #006dd2;
}

.gestao-active {
	color: #5a5a5a !important;
	border-bottom: 1px solid #5a5a5a;
	font-weight: bold;
}

.gestao-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.refresh-image {
	opacity: 0.7;
	margin-right: 10px;
}

.refresh-image:hover {
	cursor: pointer;
	opacity: 0.9;
	transform: scale(1.05);
}

.sticky-header-gestao {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 70px;
	position: sticky !important;
	top: 10px;
}

.gestao-results-scroll {
	height: 550px !important;
	overflow: scroll;
}

.gestao-sort:hover {
	cursor: pointer;
	/* transform: scale(1.01); */
	/* background: #5a5a5a; */
}

.MuiTooltip-popper .pricingTooltop {
	color: #666;
	background-color: #fff;
	margin: -4px -8px;
	padding: 15px;
	border: 1px solid #6667;
	box-shadow: 1px 1px 2px 0px #6667;
	font-size: 1.4em;
	font-weight: 400;
}

.MuiTooltip-popper .pricingTooltop .service {
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	text-transform: uppercase;
}

.MuiTooltip-popper .pricingTooltop li {
	line-height: 1.4em;
	font-size: 0.95em;
}

.MuiTooltip-popper .pricingTooltop li::before {
	content: "\2713";
	color: green;
	margin-right: 5px;
	font-weight: bold;
}

.MuiTooltip-popper .pricingTooltop .margin {
	margin-top: 15px;
	padding-top: 15px;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	text-transform: uppercase;
	border-top: 1px dotted #999;
}
.inv-modal1-table-rank,
.inv-modal1-table-seller,
.inv-modal1-table-column {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;
}
.inv-modal1-table-rank,
.inv-modal1-table-column {
	justify-content: center;
}
.inv-modal1-table-rank {
	flex: 0 1 10%;
}
.inv-modal1-table-seller {
	justify-content: flex-start;
	flex: 0 1 55%;
	width: 220px;
	margin-left: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
}
.inv-modal1-table-column {
	flex: 0 1 20%;
}
.inv-modal1-table-column.price {
  flex: 0 1 25%;
}
.inv-acc-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #5a5a5a;
}
.gestao-grid-modal-btn {
	color: #5a5a5a;
	text-align: center;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 35px;
	border-radius: 5px;
	border: 1px solid #e1e1e1;
	background: #fff;
	padding: 5px 20px;
	width: 150px;
	cursor: pointer;
}
.grid-modal-close-btn {
	color: #55718a;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 20px;
	cursor: pointer;
}
.not-integrated {
	background: #ffecec;
}

@media screen and (max-width: 1440px) {
	.gestao-sticky-block {
		width: 650px;
	}

	.gestao-height-box {
		height: 140px !important;
	}
}

@media screen and (max-width: 991px) {
	.gestao-sticky-block {
		position: initial;
		width: auto;
	}

	.gestao-select-cont {
		flex-direction: column;
	}

	.gestao-select-button {
		margin-bottom: 20px;
	}

	.gestao-select-text {
		margin-bottom: 10px;
	}

	.gestao-select-button:last-child {
		margin-bottom: 0px;
	}
}

@media screen and (max-width: 767px) {
	.gestao-sku-text {
		margin: 10px 0;
	}

	.gestao-ean-text {
		margin: 10px 0;
	}

	.gestao-title-text {
		margin: 10px;
	}

	.gestao-total-text {
		margin: 10px 0;
	}

	.gestao-box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin: 10px 0;
	}
}

/*Filter Gestao*/
.filter-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1101;
}

.filter-cont-position {
	position: absolute;
	width: 320px;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1102;
}

.filter-cont {
	background: #ffffff;
	color: #5a5a5a;
	height: 100%;
}

.filter-content {
	width: 100%;
	height: 80vh;
	overflow: auto;
}

.filter-border {
	border-top: 1px solid #e1e1e1;
	padding-bottom: 10px;
	margin-top: 10px;
}

.filter-content-cont {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0 10px;
}

.sale-cont {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	margin: 10px 0;
}

.sale-price-cont {
	display: flex;
	width: 36%;
	align-items: center;
	justify-content: space-between;
}

.sale-box {
	width: 100%;
	margin-top: 15px;
	padding: 0 10px;
}

.sale-main-line {
	background: #f1692e;
	border-radius: 5px;
	width: 100%;
	height: 5px;
	position: relative;
}

.sale-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.sale-start-box,
.sale-finish-box {
	position: absolute;
	top: -5px;
	background: #ffffff;
	border: 2px solid #f1692e;
	width: 16px;
	height: 16px;
	border-radius: 100%;
}

.sale-start-box {
	left: 0;
}

.sale-finish-box {
	right: 0;
}

.sale-price-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	color: #f1692e;
}

.mass-import-button {
	border: 1px solid #55718a;
	border-radius: 5px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #55718a;
	background: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 100px;
	justify-content: space-around;
	padding: 10px 5px;
}

.mass-import-button:hover {
	transform: scale(1.05);
}

.mass-import-option {
	width: 100%;
	background-color: #fff;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: flex-start;
	color: #5a5a5a;
	text-align: start;
	box-shadow: none;
	padding: 10px 5px;
}

.mass-import-option:hover,
.platform-list:hover {
	background: #f2f2f2;
}

.gestao-inv-title {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #5a5a5a;
}

.inv-drop-title {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-align: right;
	color: #006dd2;
}

.inv-dropdown-cont {
	background: #ffee96;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
	border: 1px solid #acacac;
	border-radius: 5px;
	width: 100%;
	padding: 20px;
}

.inv-dropdown-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}
.inv-dropdown-cancel {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	text-decoration-line: underline;
	cursor: pointer;
	color: #55718a;
}
.inv-dropdown-upload {
	display: flex;
	flex-direction: row;
	align-items: center;
	background: #006dd2;
	border-radius: 6px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	padding: 5px 10px;
}
.defaultBreakdown .info-button {
	margin-bottom: 0 !important;
}
.gestao-product-main-box {
	display: flex;
	flex-direction: column;
	min-width: 280px;
	padding: 20px;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 5px;
	flex: 1 1 24%;
	margin-bottom: 5px;
	max-width: 24%;
	justify-content: space-between;
	margin: 5px;
}
.seller-pricing-tbody .separator {
  border-bottom: 1px solid #e1e1e1;
}

.import-reviews-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

.import-reviews-dropzone .pc-modal-dropzone-text, 
.import-reviews-dropzone h4 {
  display: flex;
}

.import-reviews-dropzone img {
  filter: invert(81%) sepia(5%) saturate(0%) hue-rotate(358deg) brightness(97%) contrast(91%);
  margin-right: 5px;
}

.confirm-dialog {
  z-index: 999999 !important;
  border-radius: 8px;
  text-align: center;  
}

.confirm-dialog .MuiDialogActions-root {
  padding: 0 20px 20px;
}

.confirm-dialog button, .confirm-dialog button:hover {
  padding: 10px !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-transform: none !important;
  background-color: #E51B15;  
}

.confirm-dialog button.no {
  color: #55718a !important;
  border: 1px solid #55718a;
  background-color: #fff;
  border-radius: 6px;
}

@media screen and (max-width: 1600px) {
	.gestao-product-main-box {
		flex: 1 1 32%;
		max-width: 32%;
	}
}
@media screen and (max-width: 991px) {
	.gestao-product-main-box {
		flex: 1 1 100%;
		max-width: 100%;
	}
}

.seller-pricing-thead {
	width: 100%;
	font-size: 18px;
	color: #5a5a5a !important;
	height: 40px;
}
.seller-pricing-tr {
	width: 100%;
	height: 40px;
}
.seller-pricing-thead tr th:first-child {
	text-align: end;
}
.seller-pricing-thead tr th {
	text-align: center;
}
.seller-pricing-tbody tr td:first-child {
	width: 40%;
	text-align: end;
}

.seller-pricing-tbody tr td {
	text-align: center;
}
.seller-pricing-pvp-cont {
}
.seller-pricing-purchase-cont {
}

.seller-pricing-img-cont {
	position: relative;
}
.seller-pricing-img-image {
	display: block;
}
.seller-pricing-img-overlay {
	position: absolute;
	left: 0;
	right: 0;
	width: 0;
	height: 100%;
	overflow: hidden;
	transition: 0.5s ease;
}
.seller-pricing-img-cont:hover .seller-pricing-img-overlay {
	width: 100%;
	cursor: pointer;
}
.seller-pricing-img-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
