.payment-header {
    background: #FFFFFF;
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.payment-cont {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1000px;
    width: 100%;
    margin-top: 40px;
    padding-bottom: 40px;
}
.payment-box {
    flex: 0 1 28%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.payment-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-transform: uppercase;
    color: #5A5A5A;    
}
.payment-container {
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px;
    width: 100%;
    margin-top: 15px;
}
.payment-input {
    width: 100%;
	background: #F2F2F2;
	border: 1px solid #E1E1E1;
	border-radius: 4px;
	height: 40px;
	margin-top: 8px;
	padding-left: 10px;
}
.payment-border {
    margin: 20px 0;
    width: 100%;
    border-bottom: 1px solid #E1E1E1;
}
.payment-cost {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #5A5A5A;    
}
@media screen and (max-width: 991px) {
    .payment-cont {
        justify-content: center;
    }
    .payment-box {
        flex: 0 1 100%;
    }
    .payment-container {
        margin-bottom: 20px;
    }
    .payment-text {
        margin-left: 10px;
    }
    .payment-cont {
        max-width: 600px;
    }
    .payment-container {
        max-width: 540px;
    }
}
@media screen and (max-width: 769px) {
    .payment-cont {
        max-width: 480px;
    }
    .payment-container {
        max-width: 420px;
    }
}
@media screen and (max-width: 496px) {
    .payment-cont {
        max-width: 280px;
    }
    .payment-container {
        max-width: 220px;
    }
}
