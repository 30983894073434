.pagination-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  padding: 10px;
}

.pagination-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  margin-right: 20px;
}

.pagination-button {
  background-color: rgb(228, 222, 222);
  padding: 10px;
  margin: 4px;
  border-radius: 7px;
}

.pagination-button:hover {
  cursor: pointer;
  transform: scale(1.03);
  background-color: rgb(211, 207, 207);
}

.current-button {
  background-color: #af1b3f;
  color: #fff;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

/* Pagination admin */
.pagination-admin-button {
  padding: 3px 8px;
  width: 24px;
  height: 24px;
  margin: 4px;
  border-radius: 7px;
  color: #F14900;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-admin-button:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.current-admin-button {
  background-color: #f2f2f2;
  color: #5a5a5a;
}
.admin-button-disabled {
  pointer-events: none;
  opacity: 0.3;
}
.prev-next-color-button {
  color: #F14900 !important;
}
.pag-arrow-width {
  width: 20px !important;
}
