fieldset {
	margin-bottom: 30px;
	border: 2px rgba(0, 0, 0, 0.2) solid;
}

/* .css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
} */
legend {
	background-color: #E51B15;
	color: #fff;
	padding: 3px 6px;
	margin-left: 20px;
	border-radius: 10px;
}

.pim-title-container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	position: sticky;
	z-index: 15;
	top: 60px;
	background: #ffffff;
}

.pim-title {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin: 20px 40px;
}

.pim-title-buttons-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 20px 20px 40px;
}

.pim-title-buttons-box {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.pim-title-button {
	display: flex;
	flex-direction: row;
	background: #E51B15;
	border: 1px solid #E51B15;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 5px 10px;
	margin-right: 20px;
	height: 40px;
}

.pim-title-button:hover {
	background: #383838;
	transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.pim-green {
	background: #00a224 !important;
	border: none;
}

.pim-green:hover {
	background: #00831c !important;
}

.pim-title-button-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	padding-left: 5px;
	text-transform: initial !important;
}

.pim-app-bar {
	box-shadow: none !important;
	background: #f3f3f3 !important;
	position: sticky !important;
	top: 175px !important;
	z-index: 16 !important;
	height: 50px !important;
}

.pim-tabs-cont {
	box-shadow: none !important;
	border: none;
	background: #fff !important;
}

.pim-tab-box {
	font-family: Roboto !important;
	font-style: normal;
	font-weight: bold !important;
	font-size: 14px !important;
	line-height: 16px !important;
	display: flex;
	align-items: center;
	text-align: center;
	color: #006dd2 !important;
	border: none !important;
	text-transform: uppercase !important;
	box-shadow: none !important;
	border: 1px thick;
	min-width: 220px !important;
	opacity: 1 !important;
	background: #fff !important;
}

.pim-tab-box:focus {
	text-decoration-line: none !important;
	color: #5a5a5a !important;
}

.titles-geral-body {
	min-height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.titles-geral-container {
	flex: 1 1 auto;
	max-width: 1880px;
	padding: 15px;
}

.pim-title-legend {
	margin: 0;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	color: #5a5a5a;
	padding-bottom: 10px;
}

.pim-main-bg {
	background: #e8edf2;
}

.titles-geral-items {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
}

.pim-benefits-cont {
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	padding: 30px;
}

.titles-geral-items-row {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-around;
	width: 100%;
}

.titles-geral-item-row-name {
	font-size: 14px;
	min-width: 200px;
	display: flex;
}

.titles-geral-item-row-textarea {
	margin-left: 40px;
	width: 100%;
}

.pim-count-select {
  background: #ffffff;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  height: 40px;
  margin: 0 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  cursor: pointer;
  display: flex;
  align-items: center !important;
  justify-content:  center !important;
}

.pim-title-select-option {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.pim-title-select-option:active {
	font-weight: bold;
}

.pim-input-cont {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border: 1px solid transparent;
}

.pim-input-cont-red {
	background: #f2f2f2 !important;
	border: 1px solid #E51B15 !important;
}

.pim-buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.pim-content-input:not(:empty),
.pim-content-input:active,
.pim-content-input:focus,
.pim-content-input:hover,
.pim-content-input::placeholder {
	background: #f2f2f2 !important;
	border: 1px solid #E51B15 !important;
}

.pim-maxlength {
	font-family: Roboto;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #acacac;
}

.buttons-pim {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #e1e1e1;
	width: 100%;
	overflow: auto;
}

img {
	display: inline-block;
	position: relative;
}

.pim-language-button {
	display: flex;
	flex-direction: row;
	background: #ffffff;
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #000000;
	border: 1px solid #fff;
	box-sizing: border-box;
	margin: 10px;
}

.pim-language-button:focus {
	color: #af1b3f;
	border: 1px solid #af1b3f;
}

.info-button {
	margin: 0 7px;
}

.input-area {
	width: 1000px;
	padding: 10px;
	margin-bottom: 20px;
}

.star-icon {
	color: red;
}

.unit-select {
	width: 100px;
	padding: 10px;
	margin-left: 5px;
}

.assets-items-row {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.icon {
	background-color: #E51B15;
	opacity: 0.8;
	padding: 7px 7px 3px 7px;
	margin-left: 10px;
	border-radius: 7px;
}

.icon:hover {
	opacity: 1;
	cursor: pointer;
}

.image-container {
	background-color: #fff;
	width: 300px;
	height: 300px;
	border: 2px rgba(0, 0, 0, 0.2) solid;
}

.image {
	top: 45%;
	left: 45%;
}

.pim-main-title {
	display: flex;
	flex-direction: column;
	margin: 0 16px;
	max-width: 2560px;
	margin: auto;
}

.pim-main-title-box {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.pim-main-title-cont {
	display: flex;
	flex-direction: column !important;
	align-items: center;
	padding: 20px 27px 0 27px;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	width: 100%;
}
.pim-main-title-cont-costs {
	display: flex;
	flex-direction: row !important;
	align-items: center;
	padding: 20px 27px 0 27px;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	width: 100%;
}
.pim-costs-vendor, .pim-costs-type, .pim-costs-country {
	display: flex;
	align-items: center;
}
.pim-costs-vendor {
	justify-content: flex-start;
	flex-direction: row;
	width: 450px;
  	flex: 0 1 60%;
}
.pim-costs-type {
	justify-content: flex-start;
	width: 450px;
  	flex: 0 1 20%;
}
.pim-costs-country {
	justify-content: center;
	width: 450px;
  	flex: 0 1 20%;
}
.pim-costs-table-text {
	font-size: 14px;
	line-height: 16px;
	color: #5A5A5A;
	font-weight: 400;
}
.pim-costs-sub-country, .pim-costs-sub, .pim-costs-sub2
{
	display: flex;
	align-items: center;
}
.pim-costs-sub-country {
	justify-content: flex-start;
	flex: 0 1 22%;
	padding-left: 30px;
}
.pim-costs-sub2 {
	justify-content: center;
	flex: 0 1 13%;
}
.pim-costs-sub {
	justify-content: flex-end;
	flex: 0 1 13%;
}
.pim-main-rate {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100px;
	flex: 1 1 20%;

}


.pim-language-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

@media screen and (max-width: 1230px) {
	.buttons-pim {
		justify-content: start;
	}
}

@media screen and (max-width: 768px) {
	.pim-main-title-cont-costs {
		flex-direction: column;
	}
	.pim-title-buttons-cont {
		flex-direction: column;
	}

	.pim-title-button {
		margin-top: 20px;
	}

	.pim-buttons {
		flex-direction: column;
	}
}

@media screen and (max-width: 479px) {
	.pim-title-buttons-box {
		flex-direction: column;
	}

	.pim-count-select {
		margin: 0;
	}

	.pim-title-button {
		margin: 15px 0;
	}
}

/* Costs */
.pim-costs-title-cont-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin-right: 10px;
}

.pim-costs-title-cont-input {
	background: #ffffff;
	border: 1px solid #acacac;
	box-sizing: border-box;
	border-radius: 4px;
	width: 70px;
	height: 40px;
	padding-left: 5px;
}

.pim-main {
	margin: 20px 16px;
}

.costs-venda {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 70%;
	padding-right: 5px;
	width: 900px;
	height: 100%;
	padding-left: 10px;
}

.costs-stock {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 10%;
	padding-right: 5px;
	text-align: center;
	width: 150px;
	height: 100%;
}

.costs-preco {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 10%;
	padding-right: 5px;
	text-align: center;
	width: 150px;
	height: 100%;
}

.costs-acoes {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 10%;
	padding-right: 5px;
	text-align: center;
	width: 150px;
	height: 100%;
}

.costs-acoes:hover {
	border: 2px solid #af1b3f;
}

.costs-venda-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #5a5a5a;
}

.costs-stock-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
}

.costs-preco-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
}

.costs-acoes-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	text-decoration-line: underline;
	color: #acacac;
}

/* Avaliações */
.pim-avaliacoes-title-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.pim-avaliacoes-title-button {
	background: #5a5a5a;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	padding: 10px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pim-avaliacoes-title-button-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
	color: #ffffff;
	padding-left: 10px;
}

.pim-avaliacoes-origem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 15%;
	padding-right: 5px;
	text-align: center;
	width: 200px;
	height: 100%;
}

.pim-avaliacoes-pais {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 15%;
	padding-right: 5px;
	text-align: center;
	width: 200px;
	height: 100%;
}

.pim-avaliacoes-avaliador {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 15%;
	padding-right: 5px;
	text-align: center;
	width: 200px;
	height: 100%;
}

.pim-avaliacoes-titulo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 31%;
	padding-right: 5px;
	text-align: center;
	width: 350px;
	height: 100%;
}

.pim-avaliacoes-descricao {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 8%;
	padding-right: 5px;
	text-align: center;
	width: 120px;
	height: 100%;
}

.pim-avaliacoes-descricao:hover {
	border: 2px solid #af1b3f;
}

.pim-avaliacoes-rating {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 8%;
	padding-right: 5px;
	text-align: center;
	width: 120px;
	height: 100%;
}

.pim-avaliacoes-actions {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex: 0 8%;
	padding-right: 5px;
	text-align: center;
	width: 120px;
	height: 100%;
	border: 2px solid transparent;
}

.pim-avaliacoes-actions:hover {
	border: 2px solid #af1b3f;
}

.pim-avaliacoes-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #5a5a5a;
}

.pim-avaliacoes-text-title {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #5a5a5a;
	width: 100%;
	padding-left: 10px;
}

.pim-avaliacoes-text-desc {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	text-decoration-line: underline;
	color: #5a5a5a;
}

.pim-avaliacoes-text-desc:hover {
	color: #af1b3f;
}

.pim-subtext {
	font-family: Roboto;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #acacac;
}

.pim-inserir-desc {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #af1b3f;
	background: #ffffff;
	border: none;
	box-shadow: none;
	text-decoration-line: underline;
	text-transform: initial;
	margin-bottom: 20px;
}

.pim-modal-title-ava {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 25px;
	display: flex;
	align-items: center;
	color: #5a5a5a;
}

/*Modal pim*/
.pim-modal-input {
	background: #f2f2f2;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	border-radius: 4px;
	width: 100px;
	height: 40px;
	padding-left: 15px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 40px;
	color: #acacac;
}

.pim-modal-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: #5a5a5a;
	margin-bottom: 5px;
	text-transform: initial;
}

.pim-cont {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 20px;
}

.pim-image-box {
	min-width: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f2f2f2;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	height: 150px;
	flex-direction: column;
}

.pim-image-recursos {
	object-fit: contain;
}

.pim-content-box {
	width: 100%;
	margin-left: 40px;
}

.pim-main-title-cont div {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.pim-image-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #E51B15 !important;
	margin-top: 5px;
}

.pim-content-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	margin: 10px 0 20px;
}

.pim-content-section:first-child {
	margin-top: 0px;
}

.pim-content-input {
	background: #f2f2f2;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	border-radius: 4px;
	height: 40px;
	padding-left: 5px;
}

.pim-content-button-inactive {
	background: #ffffff;
	border: 2px solid #e1e1e1;
	box-sizing: border-box;
	border-radius: 4px;
	height: 40px;
	margin: 0 10px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #acacac;
}

.pim-content-button-active {
	background: #f2f2f2;
	border: 2px solid #5a5a5a;
	box-sizing: border-box;
	border-radius: 4px;
	height: 40px;
	margin: 0 10px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	display: flex;
	align-items: center;
	min-width: 60px;
	justify-content: center;
}

.pim-content-title-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.pim-campo-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 19px;
	color: #acacac;
	margin-left: 3px;
}

/* Premios e citacoes*/
.pim-premios-title {
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
}

.pim-premios-title div {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-decoration-line: underline;
	color: #5a5a5a;
}

.pim-prem-span {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #acacac;
}

.pim-prem-input {
	padding-left: 10px;
	background: #ffffff;
	border: 1px solid #acacac;
	box-sizing: border-box;
	border-radius: 4px;
	width: 40px;
	height: 40px;
}

/* Logistica*/
.pim-logistics-input {
	background: #f2f2f2;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	border-radius: 4px;
	padding-left: 5px;
	height: 40px;
	width: 70px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #acacac;
}

.pim-inputx2 {
	width: 140px !important;
}

.pim-logistica-subtext {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #acacac;
	margin-left: 10px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	margin-top: 15px;
}

/* Ficha */
.pim-ficha-column-cont {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}

.pim-ficha-box {
	display: flex;
	min-width: 400px;
	align-items: flex-start;
}

.pim-select-origem-dropdown {
	height: 280px;
	overflow: auto;
}

/*Translator*/
.pim-translator-cont {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding-top: 5px;
}

.pim-translator-text {
	padding: 0;
	box-shadow: none;
	background: none;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: #acacac;
	padding-left: 5px;
	text-transform: uppercase;
	cursor: pointer;
}

.pim-translator-text:hover {
	color: #5a5a5a;
	text-decoration-line: underline;
}

.pim-translator-text:focus {
	color: #159f33;
}

.pim-translator-text:focus:hover {
	color: #0b8024;
	text-decoration-line: underline;
}

.translatorbg {
	height: 100%;
	background: #e3e3e3;
	background-size: auto 50%;
}

.translator-main-modal-cont {
	display: flex;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	width: 640px;
	height: 700px;
	overflow: auto;
	padding: 20px;
}

.pim-translator-modal-cont {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
}

.pim-translator-modal-box {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
}

.pim-translator-modal-input {
	width: 100%;
	height: 150px;
	overflow: auto;
	background: #ffffff;
	border: 1px solid #acacac;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 5px;
	font-size: 14px;
	line-height: 20px;
	color: #5a5a5a;
}

.pim-translator-modal-text {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #5a5a5a;
	margin: 0 15px;
}

.pim-translator-modal-button {
	background: #5a5a5a;
	border-radius: 6px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
	height: 40px;
	width: 120px;
}

.variants-modal-cont {
	/* height: 400px; */
	/* overflow: auto; */
	max-width: 980px !important;
	position: relative;
	display: block;
	overflow: auto;
	padding: 10px 20px;
	overflow-y: scroll;
}

.variants-modal-cont span {
	padding: 5px 10px;
}

/* Versions -START- */
.version-table-overflow {
	overflow-x: auto !important;
	position: relative;
	display: block;
	width: 100%;
	/* max-width: 980px !important; */
	height: 370px;
}

.version-reg {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex: 0 25%;
	width: 200px;
	height: 100%;
	padding: 0 10px;
}

.version-delete {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 15%;
	width: 50px;
	height: 100%;
	padding: 0 10px;
}

.version-delete-button {
	padding: 3px;
}

.version-delete-button:hover {
	cursor: pointer;
	transform: scale(1.2);
}

/* Versions -END- */
@media screen and (max-width: 991px) {
	.variants-modal-cont {
		max-width: 750px !important;
	}
}

@media screen and (max-width: 769px) {
	.variants-modal-cont {
		max-width: 450px !important;
	}

	.pim-ficha-box {
		flex-direction: column;
	}

	.pim-app-bar {
		position: initial !important;
	}

	.pim-title-container {
		position: initial;
	}

	.pim-content-section {
		flex-direction: column;
	}
}

@media screen and (max-width: 496px) {
	.variants-modal-cont {
		max-width: 350px !important;
	}
}

.pim-table-result-box {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e1e1e1;
	height: 80px;
	border-left: 1px solid #e1e1e1;
	border-right: 1px solid #e1e1e1;
	box-sizing: border-box;
}

.pim-table-result-box div {
	border-top: none;
}

.pim-main-sku {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 8%;
	padding-right: 5px;
	text-align: center;
	width: 180px;
	height: 100%;
}

.pim-main-ean {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 6%;
	padding-right: 5px;
	text-align: center;
	min-width: 150px;
	height: 100%;
}

.pim-main-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 55%;
	padding-right: 5px;
	width: 100%;
	height: 100%;
	padding-left: 10px;
	text-align: start;
	margin-bottom: 40px;
}

.pim-main-producer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 20%;
	padding-right: 5px;
	text-align: center;
	width: 240px;
	height: 100%;
	padding-left: 10px;
}

.pim-main-tabs {
	width: 100%;
	display: flex;
	align-items: flex-end !important;
	background: #E51B15;
	height: 50px;
	/* margin-top: 5px; */
}

.pim-main-tabs div {
	align-items: flex-end !important;
}

.pim-main-tab {
	background: #E51B15 !important;
	color: #fff !important;
	border: none !important;
	text-decoration: initial !important;
}

.pim-main-tab:first-child {
	padding-left: 40px !important;
}

.pim-main-tab:active,
.pim-main-tab:focus,
.pim-main-tab-active {
	border-radius: 0px 10px 0px 0px !important;
	background: #ffffff !important;
	font-weight: bold !important;
	font-size: 14px !important;
	line-height: 16px !important;
	color: #5a5a5a !important;
}

.pim-main-title-select,
.pim-main-title-select:active,
.pim-main-title-select:focus,
.pim-main-title-option {
	font-weight: 500;
	font-size: 14px !important;
	line-height: 16px;
	color: #ffffff;
	background: #E51B15;
	border: 2px solid #E51B15;
	box-sizing: border-box;
	border-radius: 6px !important;
	padding: 10px;
	margin-right: 20px;
	cursor: pointer;
}

.pim-main-title-option:active,
.pim-main-title-option:focus,
.pim-main-title-select:active,
.pim-main-title-select:focus {
	background: #314455 !important;
}

.pim-main-input {
	background: #f2f2f2;
	border: 1px solid #e1e1e1 !important;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 10px 15px;
}

.pim-main-schemes-select {
	border: 1px solid #acacac;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	min-width: 150px;
	height: 40px;
}

.pim-main-schemes-select fieldset {
	border-color: transparent !important;
}

.pim-main-schemes-select:active,
.pim-main-schemes-select:focus {
	border: 1px solid #5a5a5a !important;
}

.pim-main-scheme-select-title {
	z-index: 100 !important;
	background-color: #fff !important;
	padding: 0 2px !important;
}

.pim-modal-form-container .searchsku {
	margin-left: 10px;
	cursor: pointer;
}

.pim-modal-form-container .product-title,
.pim-modal-form-container .product-ean {
	width: 100%;
}

.pim-modal-form-container .product-weight {
	width: 80px;
}

.pim-modal-form-container .components {
	border: 1px solid #ccc;
	padding: 20px;
	border-radius: 5px;
	background-color: #f0f0f0;
}

.pim-modal-form-container .components .component-sku {
	margin-right: 20px;
	width: 130px;
}

.pim-modal-form-container .components .component-qty {
	width: 50px;
}

.pim-modal-form-container .in-row {
	justify-content: space-between;
}

.pim-modal-form-container .component-line {
	display: flex;
	width: 100%;
	padding: 5px 0;
}

.pim-modal-form-container .component-line.mix div:nth-child(1) {
	width: 120px;
}

.pim-modal-form-container .component-line div:nth-child(2) {
	margin: 0 10px;
}

.pim-modal-form-container .component-line .component-title {
	font-size: small;
	text-overflow: ellipsis;
	max-width: 250px;
	white-space: nowrap;
	overflow: hidden;
	color: #999;
	flex-grow: 1;
}

.pim-modal-form-container .producer {
	width: 200px;
}

.MuiTabs-indicator {
	display: none;
}

.pim-modal-form-container label {
	cursor: pointer;
}

.pim-modal-form-container.edit-meta {
	min-width: 360px;
}

.product-main-box {
	display: flex;
	flex-direction: column;
	min-width: 280px;
	padding: 20px;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 5px;
	flex: 1 1 25%;
	margin-bottom: 5px;
	max-width: 25%;
	justify-content: space-between;
}

.product-main-box-image {
	width: 240px;
	max-height: 240px;
}

.product-main-box-title-cont {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 10px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.reverse-pim-main-box-title {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.pim-selected-products {
	display: flex;
	align-items: center;
	weight: 10%;
	background-color: #ffffff;
	border: 1px solid #5a5a5a;
	border-radius: 10px 0px 0px 10px;
	margin-left: 5px;
	padding: 5px 15px 5px 5px;
}
.pim-batch {
	background-color: #ffffff;
	border: 1px solid #5a5a5a;
	border-left: none;
	border-radius: 0px 10px 10px 0px;
	padding: 13px;
}

.pim-batch-figure {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.pim-batch-text {
	position: absolute;
	top: 30%;
	left: 27%;
	color: #000000;
	font-weight: 500;
	font-size: 14px;
}

@media screen and (max-width: 769px) {
	.product-main-box {
		flex: 1 1 100%;
	}
}
