.marketplace-heading-and-create-box {
	width: 100%;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	justify-content: space-between;
	align-items: center;
	background: #EDEBE4;
	padding: 20px 0;
}

.create-btn-lbox {
	display: flex;
	width: 185px;
	height: 40px;
	margin-left: 10px;
	justify-content: center;
	align-items: center;
	background: #E51B15;
	border-radius: 5px;
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
	color: #ffffff;
	margin-right: 10px;
}

.load-more-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.load-more-link {
	font-family: Inter, sans-serif;
	color: #262626;
	font-size: 20px;
	line-height: 24px;
}

.create-heading {
	margin-top: 16px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	line-height: 25px;
	display: flex;
	align-items: flex-start;
	color: #000000;
	width: 100%;
}

.mplc-filter-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 99.8%;
	min-height: 64px;
	background: #ffffff;
	box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
	border-radius: 5px;
}

.mplc-filter {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 35%;
	margin-left: 17px;
}

.mplc-main-title-search-value-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 99.7%;
}

.marketplace-pagination {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.search-box {
	margin-left: 10px;
	display: flex;
	align-items: center;
}

.marketplace-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 25%;
	padding-right: 5px;
	text-align: center;
	width: 350px;
	height: 100%;
	padding-left: 10px;
}

/* .marketplace-title h3:hover {
	color: #AF1B3F;
} */
.marketplace-sell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 20%;
	padding-right: 5px;
	text-align: center;
	width: 320px;
	height: 100%;
	padding-left: 10px;
}

.marketplace-skus {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 15%;
	padding-right: 5px;
	text-align: center;
	width: 120px;
	height: 100%;
}

.marketplace-pais {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 25%;
	padding-right: 5px;
	text-align: center;
	width: 180px;
	height: 100%;
}

.marketplace-actions {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 20%;
	padding-right: 5px;
	text-align: center;
	width: 100%;
	height: 100%;
}

.marketplace-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}

.marketplace-table-edit-button {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	text-decoration-line: underline;
	color: #5a5a5a;
	border: none;
	box-shadow: none;
	background: none;
	text-transform: lowercase;
}

.marketplace-vat {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 200px;
}

.marketplace-vat-input {
	width: 90px;
	height: 34px;
	margin-bottom: 10px;
	border: 1px solid #c9cacc;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 5px;
	margin-left: 10px;
}

@media screen and (max-width: 991px) {
	.marketplace-heading-and-create-box {
		margin-bottom: 20px;
		flex-direction: column;
	}

	.create-button-box {
		margin-top: 20px;
	}

	.mplc-filter-cont {
		flex-direction: column;
		padding: 10px 0;
	}
}

@media screen and (max-width: 769px) {
	.mplc-filter {
		flex-direction: column;
	}

	.mplc-main-title-search-value-cont {
		flex-direction: column;
	}
}

@media screen and (max-width: 496px) {
	.search-box {
		margin-bottom: 20px;
		flex-direction: column;
	}

	.search-input {
		width: 230px;
	}

	.create-btn {
		margin-top: 15px;
	}
}

/* Edit/create marketplace style for modal */
.formEdit {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	overflow: overlay;
	width: 100%;
	height: 600px;
}

.formEdit__container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 90%;
	padding: 0 10px;
	align-items: center;
	margin: auto;
}

.formEdit__container span {
	display: block;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: #5a5a5a;
	width: 100%;
	text-align: left;
}

.formEdit__input {
	width: 100%;
	height: 40px;
	margin-bottom: 12px;
	background: #f8f8f8;
	border: 1px solid #acacac;
	box-sizing: border-box;
	border-radius: 6px;
	padding: 8px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 40px;
	color: #5a5a5a;
}

.formEdit__button {
	width: 110px;
	height: 34px;
	background: linear-gradient(104.34deg, #d2d2d2 -15.34%, #a2a2a2 145.95%);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	line-height: 18.75px;
	margin-top: 10px;
}

.formEdit__button:hover {
	cursor: pointer;
	transform: scale(1.02);
}

.marketplace-odd-background {
	background: #ffffff !important;
}
