.navbar-2 {
  display: flex;
  height: 60px;
  padding-top: 0px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  top: 0;
  background: linear-gradient(90deg, #E51B15 0.33%, #8B0A0A 100%), linear-gradient(0deg, #E51B15, #E51B15), linear-gradient(90deg, #C42114 0.33%, #8B0A0A 100%), linear-gradient(90deg, #E32213 0.33%, #5B0000 100%), linear-gradient(270deg, #575F64 0%, #293034 100%), #314455;
  width: 100%;
}

.profile-box-header {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #fff;
  margin-right: 15px;
}

.profile-box-header:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.container {
  position: relative;
  display: flex;
  height: 60px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.header-circle-cont {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
}

.header-circle {
  border: 1px solid #fff;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.header-circle:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.breadcrumbs {
  position: sticky;
  left: 240px;
  display: flex;
  flex-direction: row;
  margin-left: 40px;
}

.breadcrumbs-box {
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-top: 5px;
}

.links {
  display: flex;
  align-items: center;
}

.nav-btn {
  display: flex;
  width: 32px;
  height: 32px;
  margin-top: 6px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.header-button-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  top: 10px;
  right: 40px;
}

.log-in-out-btn-header {
  display: flex;
  flex-direction: row;
  width: 80px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 10px;
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  box-shadow: none;
}

.user-image {
  margin-right: 5px;
}

.user-avatar {
  height: 40px;
  width: 40px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-right: 10px;
}

.wrap-menu-items {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 100;
  display: fixed;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
  flex: 0 auto;
}

.menu-items {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 50;
  display: flex;
  width: 30%;
  padding-top: 100px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.12);
  text-decoration: none;
}

.close {
  position: absolute;
  left: auto;
  top: 5%;
  right: 2%;
  bottom: auto;
  display: block;
  cursor: pointer;
}

.rigth-slash-2 {
  width: 30px;
  height: 5px;
  background-color: #444;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.left-slash-2 {
  position: relative;
  left: 0px;
  top: -5px;
  width: 30px;
  height: 5px;
  background-color: #444;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-logo {
  width: 160px;
  margin-bottom: 50px;
  color: #262626;
  text-decoration: none;
}

.menu_box {
  display: flex;
  width: 70%;
  margin-left: 0px;
  flex-direction: column;
  align-items: flex-start;
}

.menu-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-family: Inter, sans-serif;
  color: #262626;
  font-size: 26px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
}

.accordion {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.15);
  background-color: transparent;
  cursor: pointer;
}

.accordion-toggle {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  flex-direction: row;
  align-items: center;
}

.accordeion-toggle:hover {
  background-color: transparent;
}

.accordeion-toggle.w--open {
  display: block;
}

.accordeion-toggle.w--open:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.wrap-normal {
  font-family: Inter, sans-serif;
  font-size: 26px;
  line-height: 30px;
}

.accordion-content {
  background-color: transparent;
}

.accordion-content.w--open {
  position: relative;
  padding: 15px;
  background-color: #fff;
}

.dropdown-link {
  position: relative;
  z-index: 200;
  display: block;
  width: auto;
  max-width: none;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 20px;
  font-family: K2D, sans-serif;
  color: #262626;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.dropdown-link.w--current {
  color: #262626;
  text-decoration: none;
}

.dropdown-link.tools {
  z-index: 250;
  background-color: #f5f5f5;
}

.menu-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  background-color: rgba(42, 148, 250, 0.4);
  opacity: 0;
}

.image-burger {
  cursor: pointer;
}

.logo-box {
  text-align: center;
}

.settings-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 500px;
}

.users-management-button {
  display: flex;
  width: 250px;
  height: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
  color: #444;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 991px) {
  .wrap-menu-items {
    width: 90%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .menu-items {
    display: block;
    overflow: auto;
    width: 40%;
    padding-top: 40px;
  }

  .menu_box {
    margin-left: 10px;
  }

  .menu-item {
    display: block;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .links {
    flex-direction: column;
    justify-content: center;
  }

  .menu-items {
    display: block;
    overflow: auto;
    width: 60%;
    padding-top: 40px;
  }

  .close {
    top: 3%;
  }

  .menu-item {
    display: block;
    height: 30px;
    margin-bottom: 20px;
    padding-right: 10px;
    padding-bottom: 30px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 479px) {
  .header-button-box {
    right: 20px;
  }

  .links {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .wrap-menu-items {
    margin-left: 0px;
  }

  .menu-items {
    width: 100%;
  }

  .close {
    top: 1%;
    right: 1%;
    display: block;
    margin-top: 30px;
    color: #444;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
  }
}

.formPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.password-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5a5a5a;
}

.image-profile {
  margin-right: 10px;
}

.new-notification {
  position: absolute;
  bottom: -15%;
  left: 65%;
}

.number-notifications {
  position: absolute;
  top: 12%;
  right: 28%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.nav-logo-box {
  width: 200px;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  height: 60px;
  z-index: 11;
}

@media screen and (max-width: 991px) {
  .breadcrumbs {
    align-items: center;
  }

  .breadcrumbs-box {
    align-items: center;
  }

  .home-image {
    margin-top: 5px;
  }
}

@media screen and (max-width: 769px) {
  .breadcrumbs {
    display: none;
  }

  .header-circle {
    display: none;
  }
}

/*Profile Popup*/
.profile__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px;
  align-items: flex-start;
  margin: auto;
  justify-content: center;
  width: 520px;
}

.profile__container div {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
}

.profile-active-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  text-decoration-line: underline;
  color: #acacac;
}

.profile-summary {
  margin: 0 !important;
}

.profile-popup-subtitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 35px;
  color: #5a5a5a;
}

.profile-bg-image {
  position: relative;
  width: 100px;
  height: 100px;
  background: #d8ceb9 !important;
  border-radius: 6px;
  font-weight: bold !important;
  font-size: 40px !important;
  line-height: 35px !important;
  color: #ffffff !important;
}

.profile-image-text {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  top: 30px;
  left: 25px;
}

.update-password {
  border: 1px solid #e1e1e1;
  padding: 20px;
  width: 100%;
}

@media screen and (max-width: 496px) {
  .profile__container {
    width: 100%;
  }
}

/*Panel*/
.icon-menu {
  z-index: 15 !important;
  /* margin-left: 80px; */
  /* margin-top: 5px; */
  /* width: 40px; */
  box-shadow: none;
  border: none;
}

.icon-nav-panel {
  position: absolute;
  z-index: 23;
  left: 180px;
  box-shadow: none;
  border: none;
}

.arrow-panel {
  color: #acacac;
  box-shadow: none;
  border: none;
}

.panel-menu-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.burger-icon {
  fill: #ee5600 !important;
  width: 30px !important;
  margin-left: 30px;
}
