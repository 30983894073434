.single-order-cont {
	background: #ffffff !important;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	width: 100%;
	padding: 25px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 40px;
}
.single-order-title-cont {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
.single-order-cont .MuiButtonBase-root {
	color: rgba(0, 0, 0, 0.6);
}
.order-border {
	width: 100%;
	height: 0px;
	border: 1px solid #acacac;
	margin-top: 10px;
}
.single-order-title {
	font-family: "Roboto";
	font-weight: 700;
	font-size: 20px;
	line-height: 23px;
	color: #5a5a5a;
}
.single-order-title2 {
	font-family: "Roboto";
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
	color: #5a5a5a;
}
.single-order-head-text,
.single-order-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 25px;
	color: #5a5a5a;
}
.single-order-text {
	font-weight: 400;
}
.so-footer-cont {
	border: 1px solid #e1e1e1;
	padding: 20px 36px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}
.so-footer-box {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	margin: 0 20px;
}
.so-row-cont {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}
.so-squad {
	width: 50px;
}
.so-delivery {
	width: 150px;
}
.so-dispatcher {
	min-width: 400px;
	flex: 1 1 100%;
}
.so-status {
	min-width: 200px;
}
.so-image {
	width: 60px;
}
.so-product {
	min-width: 500px;
}
.so-sku,
.so-tax,
.so-price,
.so-qty,
.so-total,
.so-status {
	text-align: right !important;
}
.so-sku {
	width: 70px;
}
.so-ean {
	min-width: 120px;
	text-align: center !important;
}
.so-tax {
	min-width: 50px;
}
.so-price {
	min-width: 70px;
}
.so-qty {
	min-width: 50px;
}
.so-total {
	min-width: 70px;
}
.so-button {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #e51b15;
	border: 1px solid #e51b15;
	border-radius: 5px;
	padding: 10px;
	width: max-content;
	min-width: 200px;
}
.so-details-cont {
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0 40px;
}
.so-details-box {
	flex: 1 1 20%;
	display: flex;
	flex-direction: column;
	/* flex-direction: row; */
	align-items: flex-start;
	/* align-items: center;     */
	justify-content: center;
	/* justify-content: flex-start;     */
	margin: 10px 0;
}
.so-pickup-days {
	margin-top: 20px;
	margin-bottom: 10px;
	padding: 10px;
	background-color: #e4fbdf;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}
.so-adresse-cont {
	width: 100%;
	display: flex;
	flex-direction: row;
}
.so-blue-btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	background: #006dd2;
	border: 1px solid #006dd2;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 10px;
	margin-top: 10px;
}
.so-reject-btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #55718a;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 10px;
	box-shadow: none;
}
.so-alert-text {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	color: #f1692e;
	margin-bottom: 20px;
}
.so-purchase-text {
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #55718a;
	border: 1px solid #e1e1e1;
	border-radius: 5px;
	padding: 5px 2px;
}
.so-input-modal {
	border: 1px solid #acacac;
	border-radius: 6px;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	width: 90px;
	height: 30px;
	padding: 10px;
	text-align: right;
}
.so-btn-group {
	margin: 5px !important;
	border: 1px solid #acacac !important;
}
.so-btn-group:active,
.so-btn-group:focus,
.so-btn-group.selected {
	color: #ffffff !important;
	background: #006dd2 !important;
}
.so-blue-btn:disabled {
	opacity: 0.2;
}

.blockActions .so-blue-btn {
	opacity: 0.2;
  cursor: default;
}
.so-button.refund {
  width: auto;
  min-width: auto;
  cursor: pointer;
  padding: 5px 8px;
  color: #b40a0a;
  border: 1px solid #b40a0a;
}

.modal__body .so-details-box,
.single-order-details .so-details-box {
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}
.so-blue-btn.icon {
	background-repeat: no-repeat;
	background-position: 7px 5px;
	padding: 10px 10px 10px 40px;
}
.so-blue-btn.create_customer_invoice,
.so-blue-btn.save_customer_invoice {
	background-image: url("../../../../../../public/icons/invoice-white.svg");
}
.so-blue-btn.create_shipping_label {
	background-image: url("../../../../../../public/icons/pickup-white.svg");
}
.so-blue-btn.send_purchase,
.so-blue-btn.confirm_purchase {
	background-image: url("../../../../../../public/icons/purchase-order-white.svg");
}
.pickup-day {
	max-width: 520px;
}
.pickup-day button {
	font-size: small;
}

@media screen and (max-width: 769px) {
	.single-order-title-cont {
		flex-direction: column;
		margin: 20px 0;
	}
	.single-order-title {
		margin-bottom: 20px;
	}
	.so-details-box {
		flex: 1 1 50%;
		margin-bottom: 10px;
	}
	.so-adresse-cont {
		flex-direction: column;
	}
	.single-order-title2 {
		margin: 10px 0;
	}
}
@media screen and (max-width: 496px) {
	.so-details-box {
		flex: 1 1 100%;
	}
	.so-footer-cont {
		flex-direction: column;
	}
	.so-footer-box {
		margin: 10px 0;
	}
}
