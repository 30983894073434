.ticket-open-close-btn, .ticket-open-close-btn-active {
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #E51B15;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid transparent;
    padding-bottom: 8px;
    border-radius: 0;
}
.ticket-open-close-btn-active, .ticket-open-close-btn:active, .ticket-open-close-btn:focus {
    border-bottom: 1px solid #5A5A5A;
    color: #5A5A5A;
}
.tickets-table-reason {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    flex: 1 1 30%;
}
.tickets-table-seller {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    flex: 1 1 20%;
}
.tickets-table-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    flex: 1 1 12%;
}
.ticket-status-open, .ticket-status-closed {
    border-radius: 5px;
    color: #5A5A5A;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 15px;
}
.ticket-status-open {
    background: #FFDADF;
}
.ticket-status-closed {
    background: #E1E1E1;
}
.ticket-modal-textarea {
    resize: none;
    width: 100%;
    height: 100px;
    overflow: auto;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid #ACACAC;
    background: #F8F8F8;
}
.ticket-modal-add-btn {
    border-radius: 5px;
    border: 1px solid #E51B15;
    background: #FFF;
    color: #E51B15;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 15px;
    margin: 15px 0;
}
.s-ticket-title {
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.single-ticket-textarea {
    resize: none;
    width: 100%;
    overflow: auto;
    height: 80px;
    padding: 10px;
}
.s-ticket-send {
    border-radius: 6px;
    border: 1px solid #E51B15;
    background: #E51B15;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 10px 30px;
    width: 100%;
    margin-bottom: 5px;
    box-shadow: none;
}
.s-ticket-add {
    border-radius: 6px;
    border: 1px solid #E51B15;
    background: #FFF;
    color: #E51B15;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 30px;
    width: 100%;
    box-shadow: none;
}
.s-ticket-chat-cont {
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    max-height: 540px;
    overflow: auto;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
    color: #5A5A5A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.ticket-recipient-cont, .ticket-sender-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.ticket-sender-cont {
    align-items: flex-end
}
.ticket-recipient-box, .ticket-sender-box  {
    padding: 15px 20px;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    background: #F2F2F2;
    margin-bottom: 20px;
}
.ticket-sender-box {
    background: #ECF4FC;
}
