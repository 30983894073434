.batch-name,
.batch-user,
.batch-platform,
.batch-platform-name {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-left: 15px;
}
.batch-products,
.batch-status,
.batch-date {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-left: 5px;
}
.batch-name span {
	overflow: hidden;
	text-overflow: ellipsis;
}
.batch-name {
	width: 150px;
	flex: 0 1 14%;
}
.batch-status {
	width: 120px;
	flex: 0 1 14%;
}
.batch-user {
	width: 250px;
	flex: 0 1 25%;
}
.batch-platform {
	width: 80px;
	flex: 0 1 8%;
}
.batch-platform-name {
	width: 120px;
	flex: 0 1 20%;
}
.batch-date {
	width: 120px;
	flex: 0 1 10%;
}
.batch-products {
	width: 100px;
	flex: 0 1 10%;
}
.batch-user-image {
	width: 52px;
	height: 52px;
	background: #f39200;
	border-radius: 100%;
}
.batch-waiting,
.batch-error,
.batch-running,
.batch-finished {
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 5px 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}
.batch-waiting {
	color: #5a5a5a;
	background: #ffffff;
}
.batch-error {
	color: #ea596e;
	background: #fff5d3;
}
.batch-running {
	color: #12ac9d;
	background: #e1ffe6;
}
.batch-finished {
	color: #2b5ec5;
	background: #e2ecff;
}
.batch-text {
	font-family: "Roboto" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 30px !important;
	color: #5a5a5a !important;
}
.batch-date-br {
	display: flex;
	flex-direction: column !important;
	align-items: flex-start !important;
	line-height: 18px;
}
.batch-image-border {
	border: 1px solid #e1e1e1;
	padding: 5px;
}
.batch-error-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px;
	align-items: flex-start;
	justify-content: center;
	margin: auto;
}
.batch-textarea {
	resize: none;
	overflow: auto;
	border: 1px solid rgba(0, 0, 0, 0.4);
	min-width: 350px;
	min-height: 30px;
	padding: 5px;
	color: #606060;
	font-size: 18px !important;
}
.batch-textarea:focus {
	font-size: 18px !important;
	outline: none;
}
