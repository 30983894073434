.freight-pais {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* flex: 0 10%; */
    width: 200px; 
    height: 100%;
    padding: 0 10px;
}
.freight-reg {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* flex: 0 15%; */
    width: 200px; 
    height: 100%;
    padding: 0 10px;
}
.freight-vat {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* flex: 0 15%; */
    width: 100px; 
    height: 100%;
    padding: 0 10px;
}
.freight-prazo {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex: 0 10%; */
    width: 150px; 
    height: 100%;
}
.freight-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 5%;
    width: 100px; 
    height: 100%;
    padding-right: 40px;
}
.freight-body {
    font-size: 14px;
    line-height: 16px;
    color: #5A5A5A;
    height: 60px;
}
.freight-modal-box {
    display: flex;
    flex-direction: row;
}
.freight-modal-input {
    font-size: 16px;
    line-height: 30px;
    color: #5A5A5A;
    background: #F8F8F8;
    border: 1px solid #ACACAC;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100px;
    height: 40px;
    padding-left: 5px;
} 
.freight-modal-table-cont {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    text-align: left;
    margin-top: 20px;

}
.freight-modal-table-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 0 50%;
}
.freight-modal-column {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: center;
    flex: 0 30%;
    max-width: 100px; 
    height: 100%;
}
@media screen and (max-width: 767px) {
    .freight-modal-table-box {
        flex: 0 1 100%;
    }
    .mapping-modal-results-title, .mapping-modal-result-box {
        justify-content: center;
    }
}
