.pim-management-container {
  width: 100%;
  margin-right: auto;
}

.create-product-box {
  text-align: left;
  margin-right: 30px;
}

.pim-title-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #e2e2e2;
  padding: 20px 40px;
  width: 100%;
}

.pim-schemes-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20%;
}

.pim-schemes {
  width: 150px;
  height: 34px;
  margin-left: 30px;
  margin-bottom: 7px;
  background: linear-gradient(104.34deg, #d2d2d2 -15.34%, #a2a2a2 145.95%);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  padding: 7px;
  text-align: center;
}

.pim-schemes:hover {
  cursor: pointer;
  transform: scale(1.02);
}

/* Pim scheme START */
.pim-scheme-container {
  min-width: 80vw;
  min-height: 90vh;
  width: 80vw;
  margin: 0 auto;
}

@media screen and (max-width: 1950px) {
  .pim-scheme-container {
    min-height: 85vh;
  }
}

@media screen and (max-width: 1480px) {
  .pim-scheme-container {
    min-height: 82vh;
  }
}

.pim-scheme-title {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.pim-scheme-item-create-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
  padding-bottom: 10px;
}

.pim-create-button {
  max-width: 150px;
  height: 34px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 18.75px;
  background: #e51b15;
  border: 2px solid #e51b15;
  box-sizing: border-box;
  border-radius: 5px;
}

.pim-create-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.pim-scheme-name-cont {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pim-delete-button {
  width: 180px;
  height: 34px;
  background: #af1b3f;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
}

.pim-delete-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.pim-scheme-line {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.pim-scheme-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.pim-scheme-header-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 320px; */
}

.pim-scheme-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0px;
}

.pim-scheme-item-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.pim-scheme-item-title {
  text-align: left;
  font-size: 20px;
  margin-bottom: 7px;
  margin-right: 20px;
  background: #fff;
  padding: 15px;
  min-width: 300px;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 1950px) {
  .pim-scheme-item-title {
    max-height: 600px;
  }
}

@media screen and (max-width: 1480px) {
  .pim-scheme-item-title {
    max-height: 550px;
  }
}

.pim-scheme-item-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  position: sticky;
  top: 80px;
  /* overflow-y: scroll; */
  /* max-height: 650px; */
  background: #fff;
}

.pim-scheme-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  margin-top: 5px;
}

.pim-scheme-item-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  margin-top: 5px;
}

.item-blocks-title {
  text-align: left;
  font-size: 16px;
  min-width: 150px;
}

.tags-block-status {
  margin-left: 10px;
  font-weight: bold;
}

.tags-block-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.tags-input-container {
  border: 1px solid #b8b8b8;
  padding: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 480px;
  gap: 7px;
}

.tags-autocomplete-container {
  width: 480px;
}

.tag-item {
  background-color: rgb(230, 230, 230);
  display: inline-block;
  padding: 5px 8px 5px 12px;
  border-radius: 20px;
}

.tag-item .tag-close {
  height: 20px;
  width: 20px;
  background-color: rgb(182, 182, 182);
  display: inline-block;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.tag-close:hover {
  cursor: pointer;
  background-color: rgb(140, 140, 140);
}

.tags-input {
  flex-grow: 1;
  border: none;
  padding: 5px 10px;
  outline: none;
  background-color: transparent;
}

.item-blocks-input {
  width: 480px;
  height: 40px;
  margin-bottom: 12px;
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #5a5a5a;
}

.pim-scheme-set-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
  background: #e8edf2;
}

.pim-new-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  border: none;
  box-shadow: none;
}

.pim-items-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.pim-tabs-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.pim-tab-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  margin-top: 15px;
  margin-right: 15px;
  background-color: rgb(27, 215, 240);
}

.pim-tab-title {
  margin-right: 10px;
  padding: 5px;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  width: 150px;
  text-align: center;
}

.pim-tab-title:hover {
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: scale(1.02);
}

.pim-image {
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 3px;
  margin-right: 10px;
}

.pim-image:hover {
  cursor: pointer;
  transform: scale(1.2);
}

/* Pim scheme END */

/* Pim scheme tree START */
/* .pim-scheme-tree-container {
  width: 40%;
} */
.pim-scheme-tree-list {
  display: flex;
  flex-direction: column;
}

.pim-scheme-tree-node {
  padding: 5px 30px;
}

.pim-scheme-tree-node-items {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pim-scheme-tree-node-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.pim-scheme-tree-node-label {
  margin-right: 10px;
  /* width: 150px; */
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #006dd2;
}

.pim-scheme-tree-node-label:hover {
  cursor: pointer;
}

.pim-scheme-tree-node-icon:hover {
  cursor: pointer;
}

.pim-scheme-tree-node-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.icon-active {
  transform: rotate(90deg);
}

.icon-active:hover {
  cursor: pointer;
}

.empty-icon-active {
  margin-left: 10px;
}

/* Pim scheme tree END */

/* MODAL START */
.pim-modal-form-container {
  box-sizing: border-box;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #5a5a5a;
  width: 100%;
}

.pim-modal-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.pim-modal-title-sku-sub {
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #acacac;
}

.pim-modal-form-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;
}

.pim-modal-form-title {
  margin-right: 10px;
  text-align: left;
  min-width: 100px;
}

.pim-modal-form-select {
  padding: 10px;
  border-radius: 5px;
  width: 170px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
  background: #e5e5e5;
}

.pim-modal-input {
  width: 170px;
  height: 40px;
  margin-bottom: 12px;
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #5a5a5a;
}

.pim-modal-form-sku {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  margin-bottom: 5px;
}

.pim-modal-title-sku {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 7px;
}

.pim-modal-form-mixed {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.pim-modal-title-mixed {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  margin-left: 10px;
}

.pim-modal-form-variant {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  min-width: 150px;
}

.pim-modal-variant-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 100px;
  margin-right: 20px;
}

.pim-modal-title-variant {
  font-size: 16px;
  line-height: 30px;
  color: #5a5a5a;
  margin-left: 10px;
}

.checkbox-input {
  align-items: center;
  background: #e51b15;
  color: #fff;
  padding: 10px;
}

.pimm-input {
  width: 100%;
  height: 40px;
  margin-bottom: 12px;
  background: #f8f8f8;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #5a5a5a;
}

.pim-modal-form-button-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
}

.pim-modal-form-button-cancel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #e51b15;
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.pim-modal-form-button {
  background: #e51b15;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  height: 42px;
  padding: 0 60px;
}

.modal-export-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 10px;
}

.modal-export-cont div {
  margin: 10px 0;
}

/* MODAL END */
.pim-management-main-cont {
  margin: 0 40px;
  padding-top: 20px;
}

@media screen and (max-width: 1300px) {
  .pim-title-cont {
    flex-direction: column;
  }

  .pim-title-cont div {
    margin: 0 0 10px;
  }
}

@media screen and (max-width: 991px) {
  .pim-schemes-cont {
    flex-direction: column;
  }
}

.drag-handler {
  margin-right: 8px;
}

.drag-handler:hover {
  cursor: move;
}

.drag-handler:hover path {
  fill: #e51b15;
}
